<template>

    <div class="page-content">
        <div class="container-fluid">

            <Breadcrumb
                :pgTitle="$t('nav.contracts')"
                :pgIcon="'bx bx-folder-open'"
                :refs="refs"
                :pgCurrent="pgCurrent"
                :addNew="false">
            </Breadcrumb>

            <div class="row">

                <Loading
                    v-if="pgLoading">
                </Loading>

                <div v-if="!pgLoading" class="col-8">

                    <FormElements
                        :label="$t('view.contract_info')"
                        :labelTitle="$t('view.contract_name')"
                        :labelBody="$t('view.contract_body')"
                        
                        :hasTitle=true
                        :title_ar="row.title_ar"
                        :title_en="row.title_en"
                        
                        :hasBody=true
                        :body_ar="row.body_ar"
                        :body_en="row.body_en"
                        
                        v-on:formChange="handleFormChange">
                    </FormElements>

                </div>

                <div v-if="!pgLoading" class="col-4">
                    
                    <CardAttachments
                        :labelAttachment="$t('view.attachemnts')"
                        :attachments="row.attachments"
                        :attachment_key="row.attachment_key"
                        v-on:attachmentChange="handleAttachmentChange">
                    </CardAttachments>

                    <CardStatus
                        :status="row.status"
                        v-on:statusChange="handleStatusChange">
                    </CardStatus>
                
                </div>
            </div>


            <Buttons
                v-if="!pgLoading"
                :btnLoading="btnLoading"
                :btnCurrent="btnCurrent"
                        
                v-on:submitClicked="handelSubmit"
                v-on:cancelClicked="handleCancel">
            </Buttons>

        </div>
    </div>

</template>

<script>
import iziToast from 'izitoast';

export default {
    name: 'EditOrNew',
    components: {
        Breadcrumb: () => import('@/components/Breadcrumb.vue'),
        Loading: () => import('@/components/Loading.vue'),
        FormElements: () => import('@/components/FormElements.vue'),
        CardAttachments: () => import('@/components/CardAttachments.vue'),
        CardStatus: () => import('@/components/CardStatus.vue'),
        Buttons: () => import('@/components/Buttons.vue')
    },
    data(){
        return {    
            // auth
            auth: {
                role: '',
                access_token: '',
            },

            // row
            row: {
                title_ar: '',
                title_en: '',

                body_ar: '',
                body_en: '',

                contract_type_id: '',
                company_id: '',

                start_date: '',
                end_date: '',
                number_of_payments: '',
                amount_duo: '',

                attachment_key: 0,
                attachments: [],
                attachment_base64: [],

                status: 1,
            },
                
            msgCurrent: (this.$route.params.id) ? 'Updated' : 'Created',
            btnCurrent: (this.$route.params.id) ? 'Update' : 'Create',
            methodType: (this.$route.params.id) ? 'PUT' : 'POST',
            pgLoading: (this.$route.params.id) ? true : false,
            pgCurrent: (this.$route.params.id) ? 'Edit' : 'Add New',
            btnLoading: false,
            
            refs: 'contracts',
        }
    },
    mounted() {},
    created() {
        // AccessToken & Role
        if(localStorage.getItem('access_token')) {
            this.auth.access_token = localStorage.getItem('access_token');
        }
        if(localStorage.getItem('role')) {
            this.auth.role = localStorage.getItem('role');
        }

        if(this.$route.params.id) {
            this.fetchRow();
        }
    },
    methods: {
        
        // fetch Row
        fetchRow() {
            this.pgLoading = true;
            this.axios.defaults.headers.common = {
                'X-Requested-With': 'XMLHttpRequest', // security to prevent CSRF attacks
                'Authorization': `Bearer ` + this.auth.access_token,
            };
            const options = {
                url: window.baseURL+'/'+this.refs+'/'+this.$route.params.id,
                method: 'GET',
                data: {}
            }
            this.axios(options)
            .then(res => {
                this.pgLoading = false;

                this.row.title_ar = res.data.row.ar.title;
                this.row.title_en = res.data.row.en.title;

                this.row.body_ar = res.data.row.ar.body;
                this.row.body_en = res.data.row.en.body;

                this.row.contract_type_id = res.data.row.contract_type_id;
                this.row.company_id = res.data.row.company_id;
                this.row.amount_duo = res.data.row.amount_duo;
                this.row.start_date = res.data.row.start_date;
                this.row.end_date = res.data.row.end_date;
                this.row.number_of_payments = res.data.row.number_of_payments;
                
                // attacments
                this.row.attachment_key = res.data.row.attachment_key;
                if(res.data.row.attachments) {
                    for(let i = 1; i <= this.row.attachment_key; i++) {
                        let x = i-1;
                        this.row.attachments[i] = (res.data.row.attachments[x]) 
                                                        ? res.data.row.attachments[x].url 
                                                        : '';
                    }
                }

                this.row.status = res.data.row.status;
            })
            .catch(err => {

                // 403 Forbidden
                if(err.response && err.response.status == 401) {
                    this.clearLocalStorage();
                    this.$router.push({ name: 'login' });
                } else if(err.response && err.response.status == 403) {
                    this.$router.push({ name: 'forbidden' });
                } else {
                    this.btnLoading = false;
                    iziToast.warning({
                        icon: 'bx bx-angry',
                        title: '',
                        message: (err.response) ? err.response.data.message : ''+err
                    });
                }

            })
            .finally(() => {});
        },
            

        // handle edit Or New submit
        handelSubmit(){
            this.btnLoading = true;
            this.axios.defaults.headers.common = {
                'X-Requested-With': 'XMLHttpRequest', // security to prevent CSRF attacks
                'Authorization': `Bearer ` + this.auth.access_token,
            };

            const config = { headers: { 'Content-Type': 'multipart/form-data' }};  
            const options = {
                url: (this.$route.params.id) ? window.baseURL+'/'+this.refs+'/'+this.$route.params.id : window.baseURL+'/'+this.refs,
                method: this.methodType,
                data: {

                    ar: {
                        title: this.row.title_ar,
                        body: this.row.body_ar
                    },

                    en: {
                        title: this.row.title_en,
                        body: this.row.body_en
                    },

                    contract_type_id: this.row.contract_type_id,
                    company_id: this.row.company_id,
                    amount_duo: this.row.amount_duo,
                    number_of_payments: this.row.number_of_payments,
                    start_date: this.row.start_date,
                    end_date: this.row.end_date,

                    attachments: this.row.attachments,

                    status: this.row.status
                }
            }
            this.axios(options, config)
            .then(() => {
                this.btnLoading = false;
                iziToast.success({
                    icon: 'bx bx-wink-smile',
                    title: '',
                    message: (this.btnCurrent == 'Update') ? 'تم التعديل بنجاح' : 'تم الإضافة بنجاح'
                });
                    
                this.$router.push({ name: this.refs });
            })
            .catch(err => {
                        
                // 403 Forbidden
                if(err.response && err.response.status == 401) {
                    this.clearLocalStorage();
                    this.$router.push({ name: 'login' });
                } else if(err.response && err.response.status == 403) {
                    this.$router.push({ name: 'forbidden' });
                } else {
                    this.btnLoading = false;
                    iziToast.warning({
                        icon: 'bx bx-angry',
                        title: '',
                        message: (err.response) ? err.response.data.message : ''+err
                    });
                }
            })
            .finally(() => {})

        },

          
        // clear Local Storage
        clearLocalStorage() {
            localStorage.removeItem('access_token');
            localStorage.removeItem('avatar');
            localStorage.removeItem('username');
            localStorage.removeItem('encrypt_id');
            localStorage.removeItem('role');
        },


        // Cancel
        handleCancel(){
            if(confirm('هل انت متأكد؟')) {
                this.$router.push({ name: this.refs });
            }
        },


        // handel Changes from child components
        handleFormChange(event) {
            this.row.title_ar = event.title_ar;
            this.row.title_en = event.title_en;

            this.row.body_ar = event.body_ar;
            this.row.body_en = event.body_en;
        },

        handleContractTypeChange(event) {
            this.row.contract_type_id = event.contract_type_id;
        },

        handleCompanyChange(event) {
            this.row.company_id = event.company_id;
        },

        handleAmountDuoChange(event) {
            this.row.amount_duo = event.amount_duo;
        },

        handleDateChange(event) {
            this.row.start_date = event.start_date;
            this.row.end_date = event.end_date;
        },

        handleNumberOfPaymentsChange(event) {
            this.row.number_of_payments = event.number_of_payments;
        },

        handleAttachmentChange(event) {
            this.row.attachments = event.attachments;
            this.row.attachment_key = event.attachment_key;
        },

        handleStatusChange(event) {
            this.row.status = event.status;
        },

    },
}
</script>
